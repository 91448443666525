// Dependencies
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import { useScroll } from "react-use-gesture";
import classNames from "classnames";

// Actions
import { fetchLogout } from "../containers/login/actions";

// Selectors
import { isAuthenticated, getOrganization } from "../selector";

// Components
import MenuBalls from "./MenuBalls";
import LinkAnchor from "./LinkAnchor";
import ModalCloseSession from "./ModalCloseSession";
import Curtain from "./Curtain";
import Logo from "./Logo";
import Hamburger from "./Hamburger";

// Config
import routes from "../config/routes";

import internalization from "../config/localize"

const menuListItemsVariants = {
  initial: {
    x: -100,
    opacity: 0,
    filter: "blur(6px)",
    transition: {
      delay: 4,
    },
  },
  animate: (i) => ({
    x: 0,
    opacity: 1,
    filter: "blur(0)",
    transition: {
      delay: i * 0.1,
    },
  }),
  exit: (i) => ({
    x: -100,
    opacity: 0,
    filter: "blur(6px)",
    transition: {
      delay: i * 0.01,
    },
  }),
};

const menuIllustrationVariants = {
  initial: {
    x: "100%",
    y: "100%",
    opacity: 0,
    filter: "blur(6px)",
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
    filter: "blur(0)",
    transition: {
      delay: 0.75,
    },
  },
  exit: {
    x: "100%",
    y: "100%",
    opacity: 0,
    filter: "blur(6px)",
  },
};

const Header = (props) => {
  const menu = useRef(null);
  const nav = useRef(null);

  const [curtainActive, setCurtainActive] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const _handleScroll = useScroll(
    ({ direction: [dirX, dirY] }) => {
      document.querySelector(".s-admin")
        ? setShowHeader(true)
        : dirY < 0
        ? setShowHeader(true)
        : setShowHeader(false);
    },
    { domTarget: window }
  );

  const _listItems = () => {
    return [
      {
        href: routes.client.myProfile,
        text: internalization.header.profile,
        show: true,
      },
      {
        href: routes.client.surveys,
        text: internalization.header.surveys,
        show: true,
      },
      {
        onClick: () => {
          _handleMenu(false, _handleModal(true));
        },
        href: "#",
        text: internalization.header.close_session,
        show: props.authenticated,
      },
    ];
  };

  const removeCurtain = useCallback(
    (activeCurtain, callback) => {
      setTimeout(() => {
        setCurtainActive(activeCurtain);
        menu.current && menu.current.classList.remove("is-active");
        nav.current && nav.current.classList.remove("is-active");
        callback && callback();
      }, 650);
    },
    [showList]
  );

  const _handleMenu = (activeCurtain, callback) => {
    if (activeCurtain) {
      setCurtainActive(activeCurtain);
      // Wait 1 sec in order to show menu nav
      setTimeout(() => {
        setShowList(activeCurtain);
        nav.current.classList.add("is-active");
        menu.current.classList.add("is-active");
      }, 1000);
    } else {
      // Remove list and curtain
      setShowList(activeCurtain);
      removeCurtain(activeCurtain, callback);
    }
  };

  const _renderListItems = (items) => {
    return items.map((item, index) => {
      return (
        <motion.li
          onClick={() => _handleMenu(false)}
          className={`c-nav__list-item`}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={menuListItemsVariants}
          key={`menu-list-item-${index}`}
          custom={index}
        >
          {item.show ? (
            item.onClick ? (
              <div
                className="c-nav__link"
                onClick={item.onClick}
                data-cursor-hover
              >
                {item.text}
              </div>
            ) : (
              <LinkAnchor
                href={item.href}
                className="c-nav__link"
                text={item.text}
              />
            )
          ) : (
            []
          )}
        </motion.li>
      );
    });
  };

  const _closeSession = () => {
    _handleMenu(false);
    _handleModal(false);
    localStorage.removeItem("client");
    localStorage.removeItem("admin");
    const currentUrl = window.location.href;
    const data = { destinazione: currentUrl, channel: "fine_test" };
    window.parent && window.parent.postMessage(JSON.stringify(data), '*');    

    props.fetchLogout(
      () => props.history.push("/signin"),
      () => props.history.push("/signin")
    );
  };

  const _handleModal = (status) => {
    setShowLogoutModal(status);
  };

  const _returnLogo = () => {
    let logo;
    const notToRenderUrls = [
      routes.client.myProfile,
      routes.client.surveys,
      routes.client.home,
    ];
    if (
      props.logo &&
      !notToRenderUrls.filter((url) => url === props.location.pathname).length
    )
      logo = (
        <div className="c-logo__wrapper">
          <motion.img
            key={`company-${props.location.pathname}`}
            initial={{ y: "-110%", opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}
            exit={{ opacity: 0 }}
            src={props.logo}
            className="c-logo-image"
          />
        </div>
      );
    else
      logo = (
        <motion.div
          key={`t3-${props.location.pathname}`}
          initial={{ y: "-110%", opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 2 } }}
          exit={{ opacity: 0 }}
          className="c-logo-image"
        >
          <Logo />
        </motion.div>
      );

    return logo;
  };

  let headerClasses = classNames("c-header", !showHeader && "c-header__hidden");

  return (
    <header className={headerClasses}>
      <React.Fragment>
        <div className="c-logo">
          <LinkAnchor href={props.url_home}>
            <AnimatePresence exitBeforeEnter>{_returnLogo()}</AnimatePresence>
          </LinkAnchor>
        </div>

        {props.authenticated && (
          <div className="o-display--flex">
            {props.location.pathname === routes.client.myProfile && (
              <span
                data-cursor-hover
                className="o-margin--right"
                onClick={() => _handleModal(true)}
              >
                {internalization.header.close_session}
              </span>
            )}

            <ModalCloseSession
              isVisible={showLogoutModal}
              cancelAction={() => _handleModal(false)}
              acceptAction={() => _closeSession(showLogoutModal)}
            />
            <div
              ref={menu}
              data-cursor-hover
              className="c-menu"
              onClick={() => {
                _handleMenu(!showList);
              }}
            >
              <span className="c-menu__text">Menu</span>
              <MenuBalls />
            </div>
            <div className="c-menu-off" onClick={() => _handleModal(true)}>
              <p className="c-menu__text">Chiudi la sessione</p>
            </div>
          </div>
        )}
        {props.authenticated && (
          <nav ref={nav} className="c-nav">
            <ul className="c-nav__list">
              <AnimatePresence exitBeforeEnter>
                {showList && _renderListItems(_listItems())}
              </AnimatePresence>
            </ul>
          </nav>
        )}
        <Curtain isActive={curtainActive} />
      </React.Fragment>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    authenticated: isAuthenticated(state),
    logo: state.survey.organization_logo || getOrganization(state).logo_url,
    user_language: state.user.user_language
  };
};

const mapDispatchToProps = {
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
