import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

// Actions
import { fetchSurveyModule } from "./actions"
import { fetchLoginOk } from "../login/actions"
import {setUserLanguage} from "../users/actions"

// Components
import Congratulations from "../../containers/Congratulations";
import ProgressBar from "../../components/ProgressBar";
import SurveyQuestionsContainer from "./components/SurveyQuestionsContainer";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

//localize
import internalization from "../../config/localize"

const SurveyModuleDetail = (props) => {
  const [ module, setModule ] = useState({});
  const [ startModule, setStartModule ] = useState(false);
  const [ finished, setFinished ] = useState(false);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [isIframe, setIsIframe ] = useState(false);
  const [ modalTexts, setModalTexts ] = useState({modalErrorTitle: "", modalErrorDescription: ""});
      
  useEffect(() => {
    // internalization.setLanguage('en');
    $(window).scrollTop(0);
    props.fetchSurveyModule(props.match.params.surveyToken, props.match.params.module_id, (response) => _success(response), (response) => {_error(response)});
  }, []);

  const _success = (response) => {
    let root = document.documentElement;    
    root.style.setProperty("--primary-color", response.module.colors.primary_color);
    root.style.setProperty("--secondary-color", response.module.colors.secondary_color);    
    internalization.setLanguage(response.module.user_language);
    props.setUserLanguage(response.module.user_language)
    setModule(response.module);
    if(response.module.hasOwnProperty("user_from_token") && Object.keys(response.module.user_from_token).length > 0){
      console.log("let's reset")
      props.fetchLoginOk({data: {attributes: response.module.user_from_token}})
    }
  }

  const _error = (response) => {
    setIsModalVisible(true);
    setModalTexts({modalErrorTitle: response.message.title, modalErrorDescription: response.message.description})
  }
  const _navigateBack = () => {
    if(isIframe){
      const currentUrl = window.location.href;
      const data = { destinazione: currentUrl, channel: "fine_test" };
      window.parent && window.parent.postMessage(JSON.stringify(data), '*');
      setIsIframe(false)
      console.log("is iframe code executed")
    }else{
      let url = props.history.location.state && props.history.location.state.goBackUrl !== undefined ? props.history.location.state.goBackUrl : `/survey/${props.match.params.surveyToken}`;
      props.history.replace(url);  
    }
    
  }

  if(props.special_organization && (module.progress === 100 || finished)){
    _navigateBack()
  }
  
  return (
    <React.Fragment>
    {
      Object.keys(module).length > 0 &&
      <section className="o-wrapper">
      {
        (module.progress === 100 || finished) ?
        <Congratulations title={internalization.survey.module_finished_title} subtitle={internalization.survey.module_finished_subtitle} buttonText={internalization.buttons.accept} handleClick={() => _navigateBack() } />
        :
        <React.Fragment>
        {
          startModule ?
            <React.Fragment>
             
            <div className="o-container">
              <SurveyQuestionsContainer
                survey_token={props.match.params.surveyToken}
                module_id={props.match.params.module_id}
                internalization={internalization}
                timeLeftToNotice={module.timeLeftToNotice}
                onFinished={(is_iframe) => {setIsIframe(is_iframe);setFinished(true)}}
              />
            </div>
          </React.Fragment>

          :
          <div className="o-container">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {
                !props.special_organization && 
                <h2 className="o-margin--bottom-size-xl">
                  {module.title}
                </h2>
              }
              
              <div className="o-display--flex@lg-and-up">
                <div className={!props.special_organization && "o-col--1/2@lg"}>
                  <div
                    className="c-dangerously-html"
                    dangerouslySetInnerHTML={{ __html: module.intro }}
                  />
                  <ProgressBar title={internalization.survey.progress} progress={module.progress} className="o-margin--top-size-xxl@md-and-up"/>
                  <div className="c-button__wrapper">
                    <button
                      className="c-button c-button--grey o-margin--right-size-l"
                      onClick={() => _navigateBack() }
                    >
                      <span className="c-button__text">{internalization.buttons.back}</span>
                    </button>
                    <button
                      className="c-button"
                      onClick={() => setStartModule(true)}
                    >
                      <span className="c-button__text">{internalization.buttons.start}</span>
                    </button>
                  </div>
                </div>
                
              </div>
            </motion.div>
          </div>
        }
        </React.Fragment>
      }
      </section>
    }
    {
      isModalVisible &&
      <Modal isVisible={isModalVisible}>
        <div className="hola">
          <h2 className="o-margin--bottom-size-l">{modalTexts.modalErrorTitle}</h2>
          <p dangerouslySetInnerHTML={{__html: modalTexts.modalErrorDescription}}></p>
            <div className="c-button__wrapper">
              <Button
                className="c-button o-margin--right-auto o-margin--left-auto"
                type="button"
                text="Cerrar"
                onClick={() => 
                    props.history.replace("/")
                }
              />
            </div>
        </div>
      </Modal>
    }
    </React.Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    special_organization: state.survey.special_organization
  }
};

const mapDispatchToProps = {
  fetchSurveyModule,
  fetchLoginOk,
  setUserLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyModuleDetail);
